import React from "react";

import classes from "./BaliMou.module.scss";
import {partnershipHighlight} from "../../../constants/clemKeyOutcomes";

type Detail = {
  title?: string;
  content: string;
  italic?: boolean;
};

const details: Detail[] = [
  {
    content:
      "Sustainability Economics is an international organization headquartered in Singapore and the United States, with a major development center in Bangalore. The company provides end-to-end net-zero solutions for global businesses across high-emitting sectors. Sustainability Economics focuses on enabling profitable transitions from fossil fuels to clean energy sources. Currently, the company is pioneering an innovative approach to converge clean power with AI-powered cloud data centers, driving sustainable advancements in energy and technology.",
  },
  {
    content:
      "INZET, a not-for-profit and a key local organization in Bali and an advisor to the government of Bali will spearhead transformative community engagement initiatives, leveraging its strong local presence and grassroots expertise. Drawing on its deep understanding of Bali’s culture and environment, INZET will ensure the smooth implementation of projects that honor the island’s heritage and ecological values.  ",
  },
  {
    content:
      "The MoU establishes a comprehensive framework for collaboration between INZET and Sustainability Economics, positioning Sustainability Economics as a pivotal leader in driving Bali’s energy transition to Net-Zero.",
  },
  {
    title: "Kasu Venkata Reddy, Co-Founder/CEO of Sustainability Economics",
    content:
      '" We are honored to be appointed as Bali’s clean energy advisor and excited to collaborate with INZET on this transformative journey. Our focus will be on accelerating the energy transition by integrating clean energy development with innovative AI-powered cloud data centers. Together with INZET, we are dedicated to driving impactful change that not only advances Bali’s sustainability goals but also sets an example for global efforts toward a sustainable future."',
    italic: true,
  },
  {
    title: "Ida-Bagus Kesawa Narayana, Chairman of INZET",
    content:
      '"We are honored to partner with Sustainability Economics. Bali has set an ambitious vision to achieve net-zero emissions by 2045, ahead of the Indonesian central government’s target. A key challenge in this transformation is ensuring that sustainability initiatives are not only environmentally impactful but also financially viable. Our expertise lies in integrating cutting-edge clean energy solutions, advanced technology, and AI-driven data centers to create a unique and innovative approach. This blend of competencies ensures that the transition to sustainability is both achievable and profitable."',
    italic: true,
  },
  
];

function BaliMou() {
  return (
    <div className={classes.container}>
      <div className={classes["white-card"]}>
        <img
          className={classes["aci-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/3+2.png"
          alt="Asia Carbon Institute"
        />

        <h1 className={classes.heading}>
        Sustainability Economics Signs Landmark MoU with INZET as Bali’s Clean Energy and AI Cloud Data Center Advisor 
        </h1>

        <img
          className={classes["aci-img-mid"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/3+2.png"
          alt="Asia Carbon Institute"
        />

        <p className={classes.text}>
          <span>Bali,16th December 24,</span>  In a significant step towards achieving Bali Province’s ambitious net-zero emission goal by 2045, the Amerta Bali Lestari Foundation, operating through its unit INZET, and Sustainability Economics have signed a Memorandum of Understanding (MoU). The partnership symbolizes a shared commitment to advancing clean energy and promoting sustainable practices in the region.
        </p>
       

        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className={classes.title}>{detail.title}</p>

            <p
              className={`${detail.italic ? classes.italic : classes.text} ${
                !detail.title ? classes["apply-margin"] : ""
              }`}
            >
              {detail.content}
            </p>
          </React.Fragment>
        ))}


<p className={classes.title}>Partnership Highlights:</p>

<p className={classes.text} style={{marginTop:"0.3rem" , marginBottom:"1rem"}}>The MoU defines key areas of partnership aimed at accelerating Bali’s journey toward sustainability:</p>

{partnershipHighlight.map((outcome, index) => (
  <p className={`${classes.text} ${classes.outcome}`} key={index}>
    <span>{outcome.title}</span> - {outcome.description}
  </p>
))}

<p className={classes.text} style={{marginTop:"1.6rem"}}>This partnership represents a significant step in Bali’s efforts to achieve its net-zero emission target by 2045. By combining local expertise with global solutions, INZET and Sustainability Economics are working together to support the region’s transition to clean energy and promote sustainable practices. </p>

        <p className={classes.title}>Contact</p>
        <p className={classes.text}>Emily Smith </p>
        <p className={classes.text}>Communications manager</p>
        <p className={classes.text}>Phone: +65 98851574</p>
        <p className={classes.text}>
          Email:{" "}
          <a href="mailto:esther@sustainabilityeconomics.com">
          emily@sustainabilityeconomics.com 
          </a>
        </p>
      </div>
    </div>
  );
}

export default BaliMou;
