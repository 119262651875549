const clemKeyOutcomes = [
    {
      title: "Current state discovery",
      description:
        "Data-driven extraction of technical and financials of current state of CFPP.",
    },
    {
      title: "Transition planning",
      description:
        "Practical location/country-specific transition plan (phased approach) by collaborating with CFPP owners, power purchasers, financial institutions, and policymakers.",
    },
    {
      title: "Economic gap",
      description:
        "Practical data-driven mechanism to nail down exact dollars CFPP owners have to forego as part of the transition.",
    },
    {
      title: "Energy mix",
      description:
        "Model-driven approaches covering region/country-specific policies to identify the potential clean energy source technologies and the cost associated along with financial analysis. Build clean energy source technologies working with region/country specific partners.",
    },
    {
      title: "Transition finance",
      description:
        "Data-driven, practical approach to identify transition financing requirements mapping with clear timelines covering different business models. Constructing transition financing requirements by using innovative global financial instruments to make transition profitable and working with our equity, debt global financial partners to raise funds.",
    },
    {
      title: "Transition Credits",
      description:
        "As an end-to-end player, methodology developer, and project proponent, we serve as a one-stop shop for managing the entire lifecycle of transition credits from concept to cashout.",
    },
    {
      title: "Blockchain-driven Marketplace",
      description:
        "Source the right clean energy source technology by working with clean energy source developers to speed up the transition.",
    },
    {
      title: "Financial analysis",
      description:
        "Provide detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
    },
    {
      title: "Summary",
      description:
        "Demonstrate profitability with transitions by comparing different options for CFPP owners and financial institutions.",
    },
  ];
export const partnershipHighlight = [
    {
      title: "1. Advancing Clean Energy Development",
      description:
        "Transitioning from fossil fuels and scaling clean energy solutions like renewables (solar, wind, battery), biomass, thermal, nuclear, and hydrogen, through feasibility studies, infrastructure development, and attracting and raising investment in clean energy technologies.",
    },
    {
      title: "2. Converging Clean Power and AI Cloud Data Centers",
      description:
        "Developing AI Cloud data centers and integrating them with clean energy initiatives to accelerate the progress towards a sustainable future.",
    },
    {
      title: "3. Policy Advocacy and Support",
      description:
        "Advising the provincial government on policies and incentives to accelerate clean energy adoption and promote energy efficiency.",
    },
    {
      title: "4. Capacity Building and Education",
      description:
        "Organizing training programs and workshops to enhance the skills and knowledge among local communities, businesses, and policymakers about sustainable energy solutions.",
    },
    {
      title: "5. Community-Centric Approaches",
      description:
        "Prioritizing community involvement to ensure clean energy and AI cloud data center projects align with the needs and values of the local population, creating inclusive and impactful outcomes.",
    },
    {
      title: "6. Monitoring and Evaluation",
      description:
        "Developing systems to monitor the progress of clean energy and AI cloud data center projects and their contributions to Bali’s net-zero goal, ensuring accountability and adaptability.",
    },
   
  ];



  export const workshopHighlights = [
    {
      title: "CLEM Introduction",
      time: "8:40 – 9:00 AM",
      description:
        "Kasu Venkata Reddy, CEO & Co-founder of Sustainability Economics, will outline CLEM’s vision for transforming CFPPs into profitable clean energy sources.",
    },
  ];
  
 export const expertPanelDiscussions = [
    {
      title: "Reality Check on Clean Energy Readiness",
      time: "9:00 – 10:00 AM",
      description:
        "Industry experts will evaluate the feasibility of replacing traditional CFPPs with renewable energy solutions.",
    },
    {
      title: "Innovative Financing Instruments",
      time: "10:40 – 11:40 AM",
      description:
        "Discussion on financing options to facilitate the managed phase-out of CFPPs.",
    },
    {
      title: "Leveraging Transition Credits",
      time: "1:30 – 2:30 PM",
      description:
        "Exploring how transition credits can support profitable clean energy transitions.",
    },
    {
      title: "Financing a Just Transition",
      time: "4:00 – 4:45 PM",
      description:
        "Addressing financial strategies that ensure equitable benefits from the energy transition.",
    },
  ];
  
export  const liveDemonstrations = [
    {
      title: "Energy Mix Demonstration",
      time: "9:00 – 10:00 AM",
      description:
        "Industry experts will evaluate the feasibility of replacing traditional CFPPs with renewable energy solutions.",
    },
    {
      title: "Transition Financing Demonstration",
      time: "11:40 – 12:00 PM",
      description:
        "An analysis that demonstrates the profitability of transitioning CFPPs using innovative financial instruments.",
    },
    {
      title: "End-to-End Demonstration",
      time: "2:50 – 3:40 PM",
      description:
        "A real-time case study showcasing a complete transition from coal to clean energy.",
    },
  ];

export default clemKeyOutcomes;