
import React from "react";

import classes from "./ScottEaton.module.scss";

type Detail = {
  title?: string;
  content: string;
  italic?: boolean;
};

const details: Detail[] = [
  {
    content:
      "Scott Eaton is the CEO of Carbonplace, a global carbon credit management platform, which he joined in February 2023. He has a distinguished track record of senior leadership roles in fintech and capital markets. Prior to joining Carbonplace, Scott served as CEO of Nivaura, a capital markets fintech firm, and as CEO of Algomi, where he led the company through its acquisition by BGC in 2020. From 2015 to 2018, Scott was COO at MarketAxess, a provider of fixed income trading platforms. Earlier in his career, he worked extensively as a structured credit trader at various leading financial institutions. ",
  },
  {
    title: "Kasu Venkata Reddy, Co-Founder/CEO of Sustainability Economics",
    content:
      '"His leadership experience and deep understanding of financial markets will be invaluable as we continue to develop groundbreaking and innovative solutions for the energy transition."',
    italic: true,
  },
 
  {
    content:
      "Scott’s role underscores Sustainability Economics’ commitment to leveraging financial sector expertise to drive the global shift toward more sustainable energy solutions.",
  },
];

function ScottEaton() {
  return (
    <div className={classes.container}>
      <div className={classes["white-card"]}>
        <img
          className={classes["aci-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/9-1.png"
          alt="Scott Eaton"
        />

        <h1 className={classes.heading}>
        Scott Eaton Joins Sustainability Economics’ Advisory Board 
        </h1>

        <img
          className={classes["aci-img-mid"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/9-1.png"
          alt="Scott Eaton"
        />

        <p className={classes.text}>
          <span>Sustainability Economics</span> is pleased to announce that Scott Eaton has joined its Advisory Board. With extensive experience in financial technology and capital markets, Scott will strengthen the company’s mission to accelerate the global transition to clean energy. 
        </p>

        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className={classes.title}>{detail.title}</p>

            <p
              className={`${detail.italic ? classes.italic : classes.text} ${
                !detail.title ? classes["apply-margin"] : ""
              }`}
            >
              {detail.content}
            </p>
          </React.Fragment>
        ))}


      </div>
    </div>
  );
}

export default ScottEaton;
