const pressReleaseLinks = {
    main: "/resources/press-release",
    // aci: "/resources/press-release/aci",
    // sucofindo: "/resources/press-release/sucofindo",
    // scotteaton: "/resources/press-release/scotteaton",
    // balimou: "/resources/press-release/balimou",
    // climateweek: "/resources/press-release/balimou",
    aci: "https://www.globenewswire.com/news-release/2024/04/17/2864190/0/en/Sustainability-Economics-works-with-Asia-Carbon-Institute-of-the-TRACTION-group-to-Accelerate-Phase-Out-of-Coal-Fired-Power-Plants-in-Asia.html",
    sucofindo: "https://www.globenewswire.com/news-release/2024/07/26/2919404/0/en/Sustainability-Economics-partners-with-PT-SUCOFINDO-a-state-owned-company-of-Indonesia-to-accelerate-the-transition-of-coal-fired-power-plants-to-clean-energy-sources-in-Indonesia.html",
    scotteaton: "https://sustainabilityeconomicsnews.com/press_release/scott-eaton-joins-sustainability-economics-advisory-board/",
    balimou: "https://www.globenewswire.com/news-release/2024/12/16/2997244/0/en/Sustainability-Economics-Signs-Landmark-MoU-with-INZET-as-Bali-s-Clean-Energy-and-AI-Cloud-Data-Center-Advisor.html",
    climateweek: "https://sustainabilityeconomicsnews.com/press_release/sustainability-economics-to-host-full-day-workshop-at-climate-week-nyc-2024/",
    aigcc: "https://www.globenewswire.com/news-release/2025/01/14/3009033/0/en/Sustainability-Economics-Joins-AIGCC-to-Drive-Net-Zero-Transitions-in-Asia.html",
};

export default pressReleaseLinks;