import PressRelease from "../../PressRelease/PressRelease";
import SucoFindo from "../../PressRelease/SucoFindo/SucoFindo";
import Aci from "../../PressRelease/Aci/Aci";
import pressReleaseLinks from "./pressReleaseLinks";
import BaliMou from "../../PressRelease/BaliMou/BaliMou";
import ScottEaton from "../../PressRelease/ScottEaton/ScottEaton";
import ClimateWeek from "../../PressRelease/ClimateWeek/ClimateWeek";


const pressReleaseRoutes = [
  {
    path: pressReleaseLinks.main,
    component: PressRelease,
  },
  {
    path: pressReleaseLinks.aci,
    component: Aci,
  },
  {
    path: pressReleaseLinks.sucofindo,
    component: SucoFindo,
  },
  {
    path: pressReleaseLinks.scotteaton,
    component: ScottEaton,
  },
  {
    path: pressReleaseLinks.balimou,
    component: BaliMou,
  },
  {
    path: pressReleaseLinks.climateweek,
    component: ClimateWeek,
  },
];

export default pressReleaseRoutes;
