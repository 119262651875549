import React from "react";

import classes from "./ClimateWeek.module.scss";
import {
  expertPanelDiscussions,
  liveDemonstrations,
  workshopHighlights,
} from "../../../constants/clemKeyOutcomes";

type Detail = {
  title?: string;
  content: string;
  italic?: boolean;
};

const details: Detail[] = [
  {
    content:
      "This workshop will bring together industry experts, policymakers, and financial institutions to discuss strategies for transitioning coal-fired power plants (CFPPs) to profitable clean energy solutions.  ",
  },
  {
    content:
      "As part of the workshop’s agenda, Sustainability Economics will present CLEM, a bespoke solution designed to address the urgent need to reduce carbon emissions from coal-fired power generation. CLEM’s vision is to make energy transitions profitable today by leveraging a data-driven approach. CLEM connects CFPP asset owners, power purchasers, and policymakers to facilitate a seamless transition to renewable energy sources. ",
  },
];

function ClimateWeek() {
  return (
    <div className={classes.container}>
      <div className={classes["white-card"]}>
        <img
          className={classes["aci-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/Image+(7)+1.jpeg"
          alt="Asia Carbon Institute"
        />

        <h1 className={classes.heading}>
          Sustainability Economics to Host Full-Day Workshop at Climate Week NYC
          2024
        </h1>

        <img
          className={classes["aci-img-mid"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/Image+(7)+1.jpeg"
          alt="Asia Carbon Institute"
        />

        <p className={classes.text}>
          Sustainability Economics (SE) is pleased to announce a full-day
          workshop{" "}
          <span>
            CLEM (Clean Energy Mechanism) – Making Clean Energy Transitions
            Profitable,
          </span>{" "}
          taking place on{" "}
          <span>
            {" "}
            September 26th, at One World Trade Center, 64th Floor, New York, NY,{" "}
          </span>
          as part of <span>Climate Week NYC 2024. </span>
        </p>

        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className={classes.title}>{detail.title}</p>

            <p
              className={`${detail.italic ? classes.italic : classes.text} ${
                !detail.title ? classes["apply-margin"] : ""
              }`}
            >
              {detail.content}
            </p>
          </React.Fragment>
        ))}

        <p
          className={classes.title}
          style={{ textDecoration: "underline", marginTop: "2rem" }}
        >
          Workshop Highlights:{" "}
        </p>
        <p
          className={classes.text}
          style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
        >
          The workshop will feature four panel discussions, each focusing on
          critical aspects of the clean energy transition:{" "}
        </p>

        <ul style={{ marginLeft: "1.5rem" }}>
          {workshopHighlights.map((item, index) => (
            <li key={index}>
              <p className={classes.text}>
                <span style={{ fontWeight: 700, color: "black" }}>
                  {item.title}
                </span>{" "}
                ({item.time}): {item.description}
              </p>
            </li>
          ))}
        </ul>
        <p
          className={classes.title}
          style={{ textDecoration: "underline", marginTop: "2rem" }}
        >
          Expert Panel Discussions:{" "}
        </p>
        <p
          className={classes.text}
          style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
        >
          The workshop will feature four panel discussions, each focusing on
          critical aspects of the clean energy transition:{" "}
        </p>

        <ul
          style={{
            marginLeft: "1.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {expertPanelDiscussions.map((item, index) => (
            <li key={index}>
              <p className={classes.text}>
                <span style={{ fontWeight: 700, color: "black" }}>
                  {item.title}
                </span>{" "}
                ({item.time}): {item.description}
              </p>
            </li>
          ))}
        </ul>

        <p
          className={classes.text}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          {" "}
          In addition to the panel discussions, attendees will benefit from live
          case studies and demonstrations showcasing CLEM’s practical
          applications. These include:{" "}
        </p>

        <ul
          style={{
            marginLeft: "1.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {liveDemonstrations.map((item, index) => (
            <li key={index}>
              <p className={classes.text}>
                <span style={{ fontWeight: 700, color: "black" }}>
                  {item.title}
                </span>{" "}
                ({item.time}): {item.description}
              </p>
            </li>
          ))}
        </ul>

        <p className={classes.text} style={{ marginTop: "1.7rem" }}>
          Here is our detailed{" "}
          <a href="https://www.sustainabilityeconomics.com/resources/events-overview">
            agenda
          </a>{" "}
          and{" "}
          <a href="https://www.climateweeknyc.org/events/clem-clean-energy-mechanism-making-coal-fired-power-plants-clean-energy-sources-transitions">
            Climate Week NYC page.
          </a>{" "}
        </p>

        <p
          className={classes.title}
          style={{
            textDecoration: "underline",
            marginTop: "2rem",
            marginBottom: "1rem",
          }}
        >
          About Sustainability Economics:{" "}
        </p>

        <p className={classes.text}>
          Sustainability Economics is an international entity headquartered in
          Singapore and US, with a big development center in Bangalore. The
          company specializes in delivering comprehensive net-zero solutions
          across high-emission sectors for businesses worldwide. At the
          forefront of its offerings is CLEM (Clean Energy Mechanism), an
          innovative end-to-end solution designed to accelerate profitable clean
          energy transitions on a global scale.{" "}
        </p>

        <p className={classes.text} style={{ marginTop: "1rem" }}>
          For more information about the workshop and Sustainability Economics,
          please visit the company’s{" "}
          <a href="https://sustainabilityeconomics.com/">website</a>
        </p>

        <p className={classes.title}>Contact</p>
        <p className={classes.text}>Laura Zaporojan</p>
        <p className={classes.text}>Communications manager</p>
        <p className={classes.text}>Phone: +65 98851574</p>
        <p className={classes.text}>
          Email:{" "}
          <a href="mailto:esther@sustainabilityeconomics.com">
            laura@sustainabilityeconomics.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default ClimateWeek;
